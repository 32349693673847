import React, { useEffect, useRef } from 'react';

export const ColorChangePhoto = ({ imageUrl, newColor, style }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageUrl;

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);

      
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      
      for (let i = 0; i < data.length; i += 4) {
        data[i] = newColor.r;
        data[i + 1] = newColor.g;
        data[i + 2] = newColor.b; 
      }

      context.putImageData(imageData, 0, 0);
    };
  }, [imageUrl, newColor]);

//   return <canvas ref={canvasRef} className="max-w-full h-auto" />;
return <canvas ref={canvasRef} style={style} />;
};

// export default ColorChangePhoto;

