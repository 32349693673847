import React from "react";
import Chatbot from "react-chatbot-kit";
import "./chtwdgt.css";
import "react-chatbot-kit/build/main.css";
import MessageParser from "./chat/MessageParser";
import config from "./chat/chatbotConfig";
import ActionProvider from "./chat/ActionProvider";
import { ColorChangePhoto } from "./Canvas";
import dcpl2 from "./assets/dcpl2.png";
import "./chtwdgt.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// window.state = "domain";
const Chtwdgt = () => {
  return (
    <div
      id="chat-widget"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        border: "2px solid red",
        borderRadius: "7px",
        "@media (maxWidth: 600px)": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        headerText={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="justify-content-center" style={{ width: "30px" }}>
              <ColorChangePhoto
                imageUrl={dcpl2}
                newColor={{ r: 255, g: 0, b: 0 }}
                style={{
                  width: "250%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            </div>
            <button
              className="Closebtn"
              id="close-button"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                border: "none", 
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
          </div>
        }
      />
    </div>
  );
};

export default Chtwdgt;
