import React from "react";
import "./option.css";

const Options = ({
  handleAnydeskSupport,
  handleViewLogs,
  handleCRrequest,
  handleEmail,
  handleAnydeskverify,
  handleCRverify
}) => {
  const options = [
    {
      text: "Report a Bug",
      handler: () => {
        handleEmail("");
      },
      id: 1,
    },
    {
      text: "Ask for Remote Support",
      handler: (window.srvc === "U") ? handleAnydeskSupport : handleAnydeskverify,
      id: 2,
      condition: window.srvc === "U" || window.srvc >= 1,
    },
    {
      text: "Initiate Change Request",
      handler: (window.chng === "U") ? handleCRrequest : handleCRverify,
      id: 3,
      condition: window.chng === "U" || window.chng >= 1,
    },
    {
      text: "View Tokens",
      handler: handleViewLogs,
      id: 4,
    },
  ];

  const renderedOptions = options?.map((option) =>
    option.condition !== false ? (
      <button className="chat_btn" key={option.id} onClick={option.handler}>
        {option.text}
      </button>
    ) : null
  );

  return <div>{renderedOptions}</div>;
};

export default Options;
