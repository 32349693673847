import axios from 'axios';
import React, { useEffect, useState } from 'react';
import "./option.css";

const TokenList = ({handleTokenListValue}) => {
    const [options, setOptions] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = {
                // url: window.domain,
                url: window.urladdress,
                // url: "http://cloud.dweb.co.in:1112/arif/a/admin-dcpl-new/",
            };
            const json = JSON.stringify(data);
    
            try {
                const response = await axios.post(
                    "http://server.dweb.co.in/dcpl_erp/api/get_tokens.php",
                    json,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                
                let options = response.data.message.map((element, index) => ({
                    text: element.TICKETID,
                    handler: () => handleTokenListValue(element.TICKETID),
                    id: index,
                }));
                setOptions(options);
            } catch (error) {
                console.error(error);
                setError(error);
            }
        };

        fetchData();
    }, [handleTokenListValue]);

    if (error) {
        return <button className="chat_btn" >
        Sorry You have no token associated with this domain
    </button>;
    }

    const renderedOptions = options.map((option) => (
        <button className="chat_btn" key={option.id} onClick={option.handler}>
            {option.text}
        </button>
    ));

    return <div>{renderedOptions}</div>;
};

export default TokenList;
