import React from "react";

const MessageWithImage = (props) => {
  const { i } = props.payload;
  if (!window.ticket[0].base) {
    return null;
  }

  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px",
        margin: "10px 0",
        maxWidth: "80%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div key={i}>
        <div>
          <div>{window.ticket[i].name}</div>
          <button
            onClick={() => {
              const link = document.createElement("a");
              link.href = `data:application/octet-stream;base64,${window.ticket[i].base}`;
              link.download = window.ticket[i].name;
              link.click();
            }}
            style={{
              background: "none",
              color: "#007bff",
              border: "none",
              padding: "0",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageWithImage;
