import { createChatBotMessage } from "react-chatbot-kit";
import Options from "./Options";
import Image from "./Image";
import CustomAvatar from "./CustomAvatar";
import Options2 from "./Options2";
import Options3 from "./Options3";
import Options4 from "./Options4";
import AnydeskOption from "./AnydeskOption";
import ChangeRequestOption from "./ChangeRequestOption";
import ChangeRequestSubmitOption from "./ChangeRequestSubmitOption";
import TokenOption from "./TokenOption";
import MessageWithImage from "./MessageWithImage";
import TokenList from "./TokenList";
import SupportFile from "./SupportFile";
import AdditionalConcernOption from "./AdditionalConcernOption";
import AdditionalScreenshotOption from "./AdditionalScreenshotOption";
import SubmitOptions from "./SubmitOptions";
import InitialOption from "./InitialOption";
import DateTimePickerWidget from "./DateTimePickerWidget";

const config = {
  initialMessages: [
    createChatBotMessage("Welcome to DCPL", {
      widget: "InitialOptions",
    }),
  ],
  customComponents: {
    botAvatar: (props) => <CustomAvatar {...props} />,
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "red",
    },

    chatHeader: {
      backgroundColor: "red",
    },

    userMessageBox: {
      backgroundColor: "red",
    },

    chatContainer: {
      backgroundColor: "red",
      "@media (max-width: 768px)": {
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",
      },
    },

    chatButton: {
      backgroundColor: "red",
    },
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => (
        <Options
          {...props}
          handleBugReport={props.actionProvider.handleBugReport}
          handleName={props.actionProvider.handleName}
          handlePhone={props.actionProvider.handlePhone}
          handleEmail={props.actionProvider.handleEmail}
          handleAnydeskSupport={props.actionProvider.handleAnydeskSupport}
          handleViewLogs={props.actionProvider.handleViewLogs}
          handleCRrequest={props.actionProvider.handleCRrequest}
          handleAnydeskverify={props.actionProvider.handleAnydeskverify}
          handleCRverify={props.actionProvider.handleCRverify}
        />
      ),
    },
    {
      widgetName: "image",
      widgetFunc: (props) => {
        <Image {...props} />;
      },
    },
    {
      widgetName: "screenshot",
      widgetFunc: (props) => (
        <Options2
          {...props}
          handleScreenshot={props.actionProvider.handleScreenshot}
          handleNoScreenshot={props.actionProvider.handleNoScreenshot}
        />
      ),
    },
    {
      widgetName: "submit",
      widgetFunc: (props) => (
        <Options3
          {...props}
          handleSubmit={props.actionProvider.handleSubmit}
          handleContinue={props.actionProvider.handleContinue}
          handleAlreadySubmitted={props.actionProvider.handleAlreadySubmitted}
        />
      ),
    },
    {
      widgetName: "attachment",
      widgetFunc: (props) => (
        <Options4
          {...props}
          handleAttachmentUploadYes={
            props.actionProvider.handleAttachmentUploadYes
          }
          handleAttachmentUploadNo={
            props.actionProvider.handleAttachmentUploadNo
          }
        />
      ),
    },
    {
      widgetName: "anydesk",
      widgetFunc: (props) => (
        <AnydeskOption
          {...props}
          handleAnydeskSupport={props.actionProvider.handleAnydeskSupport}
          handleAnydeskCancel={props.actionProvider.handleAnydeskCancel}
        />
      ),
    },
    {
      widgetName: "changerequest",
      widgetFunc: (props) => (
        <ChangeRequestOption
          {...props}
          handleCRrequest={props.actionProvider.handleCRrequest}
          handleCRcancel={props.actionProvider.handleCRcancel}
        />
      ),
    },
    {
      widgetName: "changerequestsubmit",
      widgetFunc: (props) => (
        <ChangeRequestSubmitOption
          {...props}
          handleSubmit={props.actionProvider.handleSubmit}
          handleCRcontinuerequest={props.actionProvider.handleCRcontinuerequest}
          handleAlreadySubmitted={props.actionProvider.handleAlreadySubmitted}
        />
      ),
    },
    {
      widgetName: "tokenoption",
      widgetFunc: (props) => (
        <TokenOption
          {...props}
          handleManualToken={props.actionProvider.handleManualToken}
          handleTokenList={props.actionProvider.handleTokenList}
        />
      ),
    },
    {
      widgetName: "messageWithImage",
      widgetFunc: (props) => <MessageWithImage {...props} />,
    },
    {
      widgetName: "Tokenlist",
      widgetFunc: (props) => (
        <TokenList
          {...props}
          handleTokenListValue={props.actionProvider.handleTokenListValue}
        />
      ),
    },
    {
      widgetName: "supportwithimage",
      widgetFunc: (props) => <SupportFile {...props} />,
    },
    {
      widgetName: "additionalconcern",
      widgetFunc: (props) => (
        <AdditionalConcernOption
          {...props}
          handleConcernYes={props.actionProvider.handleConcernYes}
          handleConcernNo={props.actionProvider.handleConcernNo}
        />
      ),
    },
    {
      widgetName: "AdditionalScreenshot",
      widgetFunc: (props) => (
        <AdditionalScreenshotOption
          {...props}
          handleAdditionalScreenshotYes={
            props.actionProvider.handleAdditionalScreenshotYes
          }
          handleAdditionalScreenshotNo={
            props.actionProvider.handleAdditionalScreenshotNo
          }
        />
      ),
    },
    {
      widgetName: "AdditionalSubmit",
      widgetFunc: (props) => (
        <SubmitOptions
          {...props}
          handleSubmit2={props.actionProvider.handleSubmit2}
          handleContinue2={props.actionProvider.handleContinue2}
          handleAlreadySubmitted={props.actionProvider.handleAlreadySubmitted}
        />
      ),
    },
    {
      widgetName: "InitialOptions",
      widgetFunc: (props) => (
        <InitialOption
          {...props}
          handeNewBusinessEnquiryName={
            props.actionProvider.handeNewBusinessEnquiryName
          }
          handleExistingEnterDomain={
            props.actionProvider.handleExistingEnterDomain
          }
        />
      ),
    },
    {
      widgetName: "datetime",
      widgetFunc: (props) => (
        <DateTimePickerWidget {...props} 
        handeNewBusinessEnquirySubmit={props.actionProvider.handeNewBusinessEnquirySubmit}
        />
      ),
    },
  ],
};

export default config;
