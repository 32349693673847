import React from 'react'

const SupportFile = (props) => {
    const {file}=props.payload;
    const {name}=props.payload;
  return (
    <div
            style={{
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "10px",
              margin: "10px 0",
              maxWidth: "80%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <div>
                <div>{name}</div>
                <button
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = `data:application/octet-stream;base64,${file}`;
                    link.download = name;
                    link.click();
                  }}
                  style={{
                    background: "none",
                    color: "#007bff",
                    border: "none",
                    padding: "0",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
  )
}

export default SupportFile