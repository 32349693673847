import React from 'react'
import "./option.css";

const AdditionalConcernOption = ({handleConcernYes, handleConcernNo}) => {
    const options = [
        {
          text: "Yes",
          handler: handleConcernYes,
          id: 1,
        },
        {
          text: "No",
          handler: handleConcernNo,
          id: 2,
        },
      ];
    
      const renderedOptions = options?.map((option) => (
        <button className="chat_btn" key={option.id} onClick={option.handler}>
          {option.text}
        </button>
      ));
    
      return <div>{renderedOptions}</div>;
}

export default AdditionalConcernOption