import React from 'react'
import "./option.css";

const InitialOption = ({handeNewBusinessEnquiryName,handleExistingEnterDomain}) => {
    const options = [
        {
          text: "New Business Enquiry",
          handler: handeNewBusinessEnquiryName,
          id: 1,
        },
        {
          text: "Existing Customer",
          handler: handleExistingEnterDomain,
          id: 2,
        },
      ];
    
      const renderedOptions = options?.map((option) => (
        <button className="chat_btn" key={option.id} onClick={option.handler}>
          {option.text}
        </button>
      ));
      
    
      return <div>{renderedOptions}</div>;
}

export default InitialOption