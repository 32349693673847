import React from "react";

const TokenOption = ({ handleManualToken, handleTokenList }) => {
  const options = [
    {
      text: "Enter Token Number Manually",
      handler: handleManualToken,
      id: 1,
    },
    {
      text: "Show Token List",
      handler: handleTokenList,
      id: 2,
    },
  ];

  const renderedOptions = options?.map((option) => (
    <button className="chat_btn" key={option.id} onClick={option.handler}>
      {option.text}
    </button>
  ));

  return <div>{renderedOptions}</div>;
};

export default TokenOption;
