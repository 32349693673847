import React from 'react'
import "./option.css";

const AnydeskOption = ({handleAnydeskSupport,handleAnydeskCancel}) => {
  
    const options = [
        {
          text: "Proceed",
          handler: handleAnydeskSupport,
          id: 1,
        },
        {
          text: "Cancel",
          handler: handleAnydeskCancel,
          id: 2,
        },
      ];
    
      const renderedOptions = options?.map((option) => (
        <button className="chat_btn" key={option.id} onClick={option.handler}>
          {option.text}
        </button>
      ));
    
      return <div>{renderedOptions}</div>;
    };
    
  


export default AnydeskOption