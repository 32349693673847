import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./option.css";

function DateTimePickerWidget({ handeNewBusinessEnquirySubmit }) {
  const [dateTime, setDateTime] = useState(new Date());
  const minDateTime = new Date(); // Current date and time

  const onChange = (value) => {
    setDateTime(value);
  };

  const logDateTime = () => {
    handeNewBusinessEnquirySubmit(dateTime);
  };

  return (
    <div>
      <DateTimePicker
        onChange={onChange}
        value={dateTime}
        minDate={minDateTime}
      />
      <button className="chat_btn" onClick={logDateTime}>
        Submit
      </button>
    </div>
  );
}

export default DateTimePickerWidget;
