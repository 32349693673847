import React from 'react'
import "./option.css";

const SubmitOptions = ({ handleSubmit2, handleContinue2, handleAlreadySubmitted }) => {
    const options = [
        {
          text: "Submit",
          handler: window.Sbmt === 0 ? handleSubmit2 : handleAlreadySubmitted,
          id: 1,
        },
        {
          text: "Continue",
          handler: window.Sbmt === 0 ? handleContinue2 : handleAlreadySubmitted,
          id: 2,
        },
      ];
    
      const renderedOptions = options?.map((option) => (
        <button className="chat_btn" key={option.id} onClick={option.handler}>
          {option.text}
        </button>
      ));
    
      return <div>{renderedOptions}</div>;
}

export default SubmitOptions