class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  async parse(message) {
    const lowerCaseMessage = message.toLowerCase();

    if (message === "ImageUpload") {
      this.state.waitingFor = "ImageUpload";
    }
    const base = window.screenshotBase64;

    if (this.state.waitingFor === "handleOTP") {
      this.actionProvider.handleOtp(message);
    } else if (this.state.waitingFor === "handleName") {
      this.actionProvider.handeNewBusinessEnquiryPhone(message);
    } else if (this.state.waitingFor === "handlePhone") {
      var regex = /^[789]\d{9}$/;
      if (regex.test(message)) {
        this.actionProvider.handeNewBusinessEnquiryEmail(message);
      } else {
        this.actionProvider.handeNewBusinessEnquiryPhone2();
      }
    } else if (this.state.waitingFor === "handleEmail") {
      var regex2 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (regex2.test(message)) {
        this.actionProvider.handeNewBusinessEnquiryRequirement(message);
      } else {
        this.actionProvider.handeNewBusinessEnquiryEmail2();
      }
    } else if (this.state.waitingFor === "handleRequirement") {
      this.actionProvider.handeNewBusinessEnquiryDate(message);
    } else if (this.state.waitingFor === "handleDate") {
      this.actionProvider.handeNewBusinessEnquirySubmit(message);
    } else if (this.state.waitingFor === "otp") {
      const status = await this.actionProvider.handleotpverify(message);
      if (status) {
        this.actionProvider.handleDomain(message);
      } else {
        this.actionProvider.handleOtp2();
      }
    } else if (this.state.waitingFor === "concern2") {
      this.actionProvider.handleAddConcern(message);
    } else if (this.state.waitingFor === "token") {
      this.actionProvider.handleManualTokenValue(message);
    } else if (this.state.waitingFor === "anydesk") {
      this.actionProvider.handleAnydeskId(message);
    } else if (this.state.waitingFor === "cr") {
      this.actionProvider.handleAttachmentUpload(message);
    } else if (this.state.waitingFor === "name") {
      this.actionProvider.handleName(message);
    } else if (this.state.waitingFor === "phone") {
      let num = Number(message);
      if (!isNaN(num) && message.length === 10) {
        this.actionProvider.handlePhone(message);
      } else {
        this.actionProvider.handleName2(message);
      }
    } else if (this.state.waitingFor === "email") {
      if (message.includes("@")) {
        this.actionProvider.handleEmail(message);
      } else {
        this.actionProvider.handlePhone2(message);
      }
    } else if (this.state.waitingFor === "concern") {
      this.actionProvider.handleConcern(message);
    } else if (this.state.waitingFor === "additionalconcern") {
      this.actionProvider.handleAddConcern(message);
    } else if (this.state.waitingFor === "ImageUpload") {
      if (base) {
        this.actionProvider.handleScreenshot(message);
      } else {
        this.actionProvider.handleConcern(message);
      }
    } else if (this.state.waitingFor === "second") {
      this.actionProvider.handleSecondMessage(message);
    } else if (this.state.waitingFor === "submitOrContinue") {
      if (lowerCaseMessage.includes("submit")) {
        this.actionProvider.handleSubmit();
      } else {
        this.actionProvider.handleContinue();
      }
    } else if (this.state.waitingFor === "last") {
      this.actionProvider.handleNew();
    }
  }
}

export default MessageParser;
