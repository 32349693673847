import axios from "axios";
import BoldMessage from "./BoldMessage";
import ConcernComponent from "./ConcernComponent";

window.screenshotBase64 = null;
window.customername = null;
window.ph = null;
window.email = null;
window.concern = null;
window.concern2 = null;
window.type = null;
window.urlofpage = window.location.href;
window.id = null;
window.otp = null;
window.concerns = [];
window.concerns2 = [];
window.ticket = [];
let index = 0;
let index2 = 0;
class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
  }

  handeNewBusinessEnquiryName = () => {
    const message = this.createChatBotMessage("Please Enter your name");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "handleName",
    }));
  };
  handeNewBusinessEnquiryPhone = (name) => {
    window.leadname = name;
    const message = this.createChatBotMessage("Please Enter your Phone Number");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "handlePhone",
    }));
  };
  handeNewBusinessEnquiryPhone2 = () => {
    const message = this.createChatBotMessage(
      "This is not a valid phone number. Please Enter a valid Phone number to continue"
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "handlePhone",
    }));
  };
  handeNewBusinessEnquiryEmail = (phone) => {
    window.leadphone = phone;
    const message = this.createChatBotMessage("Please Enter your Email ID");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "handleEmail",
    }));
  };
  handeNewBusinessEnquiryEmail2 = () => {
    const message = this.createChatBotMessage(
      "This is not a valid Email Id. Please Enter a correct Email Id to continue"
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "handleEmail",
    }));
  };
  handeNewBusinessEnquiryRequirement = (email) => {
    window.leademail = email;
    const message = this.createChatBotMessage("Please Enter your Requirement");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "handleRequirement",
    }));
  };
  handeNewBusinessEnquiryDate = (requirement) => {
    window.leadrequirement = requirement;
    const message = this.createChatBotMessage(
      "Please Enter your comfortable Date & Time for Call Back",
      {
        widget: "datetime",
      }
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "handleDate",
    }));
  };
  handeNewBusinessEnquirySubmit = async (dateTime) => {
    const date = new Date(dateTime);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = date.toLocaleString("en-US", options);
    const message2 = this.createClientMessage(formattedDate);
    this.addMessageToState(message2);
    const data = {
      name: window.leadname,
      email: window.leademail,
      phone: window.leadphone,
      requirement: window.leadrequirement,
      comfortable_date_time: formattedDate,
      url: window.location.href,
    };

    const config = {
      method: "post",
      url: "http://server.dweb.co.in/dcpl_erp/api/add_lead.php",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    let message = "";
    try {
      const response = await axios(config);
      message = this.createChatBotMessage(response.data.message);
    } catch (error) {
      message = this.createChatBotMessage(
        "We are facing some problem. Sorry for the inconvenience."
      );
    }
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };
  handleExistingEnterDomain = () => {
    const message = this.createChatBotMessage(
      "Enter your Domain Name/Dedicated IP"
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "handleOTP",
    }));
  };

  handleAnydeskSupport = () => {
    window.type = "Ask for Anydesk Support";
    const message = this.createChatBotMessage("Please enter you anydesk id");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "anydesk",
    }));
  };
  handleAnydeskverify = () => {
    const message = this.createChatBotMessage(
      `You have ${window.srvc} requests left. Do you wish to continue?`,
      {
        widget: "anydesk",
      }
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "anydesk",
    }));
  };
  handleAnydeskCancel = async () => {
    const message = this.createChatBotMessage(
      `Your Anydesk service request has been cancelled`
    );
    setTimeout(() => {
      const message2 = this.createChatBotMessage(
        `Hi ${window.customername}, how can we help you?`,
        {
          widget: "options",
          withAvatar: true,
        }
      );
      this.addMessageToState(message2);
    }, 2000);

    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleViewLogs = () => {
    window.type = "View Tokens";
    const message = this.createChatBotMessage(
      "Do you want to manually enter you token number or you want to see the list of tokens",
      {
        widget: "tokenoption",
      }
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };
  handleManualToken = () => {
    const message = this.createChatBotMessage("Please Enter your Token Number");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "token",
    }));
  };
  handleManualTokenValue = async (number) => {
    const data = {
      ticket_id: number,
    };
    const json = JSON.stringify(data);

    try {
      const response = await axios.post(
        "http://server.dweb.co.in/dcpl_erp/api/get_token_details.php",
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      window.tokenId = response.data.message.id;
      const message2 = this.createChatBotMessage(
        <>
          <h3>
            <strong>Token Number: </strong> {number}
          </h3>
          <BoldMessage text={response.data.message.support_for} />
        </>,
        {
          withAvatar: true,
        }
      );
      this.addMessageToState(message2);
      response.data.message.concerns.forEach((concern, index) => {
        window.ticket[index] = { base: concern.file };
        window.ticket[index].name = concern.file_name;
        window.tindex = index;
        const message3 = this.createChatBotMessage(`Concern ${index + 1}`, {
          withAvatar: true,
        });
        this.addMessageToState(message3);
        const message = this.createClientMessage(`${concern.message}`, {
          widget: "messageWithImage",
          withAvatar: true,
          payload: { i: index },
        });
        this.addMessageToState(message);
        if (concern.status !== null) {
          const message4 = this.createChatBotMessage(
            <ConcernComponent status={"Status"} text={concern.status} />
          );
          this.addMessageToState(message4);
        }
        if (concern.remarks !== null) {
          const message5 = this.createChatBotMessage(
            <ConcernComponent status={"Remarks"} text={concern.remarks} />,
            {
              withAvatar: true,
            }
          );
          this.addMessageToState(message5);
        }
        if (concern.support_file !== null && concern.support_file !== "") {
          const messagesupport = this.createChatBotMessage(
            "Your Support File",
            {
              widget: "supportwithimage",
              withAvatar: true,
              payload: {
                file: concern.support_file,
                name: concern.support_file_name,
              },
            }
          );
          this.addMessageToState(messagesupport);
        }
        if (concern.concern_status !== null) {
          const messagestatus = this.createChatBotMessage(
            <ConcernComponent
              status={"Concern Status"}
              text={concern.concern_status}
            />,
            {
              withAvatar: true,
            }
          );
          this.addMessageToState(messagestatus);
        }
      });
      setTimeout(() => {
        const messageConcern = this.createChatBotMessage(
          "Do You Have any Concern over the Earlier Response?",
          {
            widget: "additionalconcern",
            withAvatar: true,
          }
        );
        this.addMessageToState(messageConcern);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  addMessageToState = (message) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };

  handleTokenList = async () => {
    let message = "";
    const data = {
      url: window.urladdress,
    };
    const json = JSON.stringify(data);

    try {
      const response = await axios.post(
        "http://server.dweb.co.in/dcpl_erp/api/get_tokens.php",
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      window.token = response.data.status;
    } catch (error) {
      console.error(error);
    }
    if (window.token) {
      message = this.createChatBotMessage("These are your tokens", {
        widget: "Tokenlist",
      });
    } else {
      message = this.createChatBotMessage(
        "Sorry we have no tokens associated with this domain"
      );
    }

    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleTokenListValue = async (tkn) => {
    const data = {
      ticket_id: tkn,
    };
    const json = JSON.stringify(data);

    try {
      const response = await axios.post(
        "http://server.dweb.co.in/dcpl_erp/api/get_token_details.php",
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      window.tokenId = response.data.message.id;
      const message2 = this.createChatBotMessage(
        <>
          <h3>
            <strong>Token Number: </strong> {tkn}
          </h3>
          <BoldMessage text={response.data.message.support_for} />
        </>,
        {
          withAvatar: true,
        }
      );
      this.addMessageToState(message2);
      response.data.message.concerns.forEach((concern, index) => {
        window.ticket[index] = { base: concern.file };
        window.ticket[index].name = concern.file_name;
        window.tindex = index;
        const message3 = this.createChatBotMessage(`Concern ${index + 1}`, {
          withAvatar: true,
        });
        this.addMessageToState(message3);
        const message = this.createClientMessage(`${concern.message}`, {
          widget: "messageWithImage",
          withAvatar: true,
          payload: { i: index },
        });
        this.addMessageToState(message);
        if (concern.status !== null) {
          const message4 = this.createChatBotMessage(
            <ConcernComponent status={"Status"} text={concern.status} />
          );
          this.addMessageToState(message4);
        }
        if (concern.remarks !== null) {
          const message5 = this.createChatBotMessage(
            <ConcernComponent status={"Remarks"} text={concern.remarks} />,
            {
              withAvatar: true,
            }
          );
          this.addMessageToState(message5);
        }
        if (concern.support_file !== null && concern.support_file !== "") {
          const messagesupport = this.createChatBotMessage(
            "Your Support File",
            {
              widget: "supportwithimage",
              withAvatar: true,
              payload: {
                file: concern.support_file,
                name: concern.support_file_name,
              },
            }
          );
          this.addMessageToState(messagesupport);
        }
        if (concern.concern_status !== null) {
          const messagestatus = this.createChatBotMessage(
            <ConcernComponent
              status={"Concern Status"}
              text={concern.concern_status}
            />,
            {
              withAvatar: true,
            }
          );
          this.addMessageToState(messagestatus);
        }
      });
      setTimeout(() => {
        const messageConcern = this.createChatBotMessage(
          "Do You Have any Concern over the Earlier Response?",
          {
            widget: "additionalconcern",
            withAvatar: true,
          }
        );
        this.addMessageToState(messageConcern);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  handleConcernYes = () => {
    const message = this.createChatBotMessage("Please Enter your Concern");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails },
      waitingFor: "additionalconcern",
    }));
  };
  handleAddConcern = (concern) => {
    if (window.concerns2[index2]) {
      index2++;
    }
    window.concerns2[index2] = { message: concern };
    const message = this.createChatBotMessage(
      "Do you want to take the Screenshot",
      {
        widget: "AdditionalScreenshot",
      }
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails },
    }));
  };
  handleAdditionalScreenshotYes = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      const videoTrack = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(videoTrack);

      const screenshotBlob = await imageCapture.grabFrame();

      try {
        const canvas = document.createElement("canvas");
        canvas.width = screenshotBlob.width;
        canvas.height = screenshotBlob.height;
        const context = canvas.getContext("2d");
        context.drawImage(
          screenshotBlob,
          0,
          0,
          screenshotBlob.width,
          screenshotBlob.height
        );

        canvas.toBlob(async (blob) => {
          const reader = new FileReader();
          reader.onloadend = function () {
            let base64data = reader.result;
            base64data = base64data.split("data:image/png;base64,")[1];
            if (!window.concerns[index]) {
              window.concerns[index] = {};
            }
            window.concerns2[index2].screenshot = base64data;
            window.concerns2[index2].screenshot_name = `${index}.png`;
          };

          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error("Error creating blob URL:", error);
      }

      stream.getTracks()[0].stop();
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }

    const message = this.createChatBotMessage(
      "Screenshot uploaded. Do you want to submit or continue?",
      {
        widget: "AdditionalSubmit",
      }
    );
    window.Sbmt = 0;

    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "submitOrContinue2",
    }));
  };
  handleAdditionalScreenshotNo = () => {
    if (!window.concerns[index]) {
      window.concerns[index] = {};
    }
    window.concerns2[index2].screenshot = "";
    window.concerns2[index2].screenshot_name = ``;
    const message = this.createChatBotMessage(
      "We got your concern. Do you want to submit or continue?",
      {
        widget: "AdditionalSubmit",
      }
    );
    window.Sbmt = 0;

    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "submitOrContinue2",
    }));
  };
  handleContinue2 = () => {
    const message = this.createChatBotMessage(
      "Please continue with your concern."
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "concern2",
    }));
  };
  handleSubmit2 = async () => {
    const data = {
      ticket_id: window.tokenId,
      concerns: window.concerns2,
    };

    const config = {
      method: "post",
      url: "http://server.dweb.co.in/dcpl_erp/api/update_ticket.php",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    let message = "";
    try {
      const response = await axios(config);
      message = this.createChatBotMessage(
        "Your Concern Updated successfully. Thanks for contacting us!!"
      );
    } catch (error) {
      console.error(error);
      message = this.createChatBotMessage(
        "An unexpected error occurred. Sorry for the inconvenience!!"
      );
    }
    window.Sbmt = 1;
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleConcernNo = () => {
    const message = this.createChatBotMessage("Thanks for contacting us!!");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails },
    }));
  };

  handleCRrequest = (email) => {
    window.type = "Initiate Change Request";
    const message = this.createChatBotMessage("Please Enter your request");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, email },
      waitingFor: "cr",
    }));
  };
  handleCRcontinuerequest = (email) => {
    const message = this.createChatBotMessage(
      "Please continue with your request"
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, email },
      waitingFor: "cr",
    }));
  };
  handleCRverify = (email) => {
    const message = this.createChatBotMessage(
      `You have ${window.chng} requests left. Do you wish to continue?`,
      {
        widget: "changerequest",
      }
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, email },
      waitingFor: "cr",
    }));
  };
  handleCRcancel = async (email) => {
    const message = this.createChatBotMessage(
      `Your Change request has been cancelled`
    );

    setTimeout(() => {
      const message2 = this.createChatBotMessage(
        `Hi ${window.customername}, how can we help you?`,
        {
          widget: "options",
          withAvatar: true,
        }
      );
      this.addMessageToState(message2);
    }, 2000);
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, email },
    }));
  };

  handleAttachmentUpload = (email) => {
    if (window.concerns[index]) {
      index++;
    }
    window.concerns[index] = { message: email };
    const message = this.createChatBotMessage(
      "Do you want to upload any attachments?",
      {
        widget: "attachment",
      }
    );
    window.Sbmt = 0;
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, email },
      waitingFor: "cr",
    }));
  };
  handleAttachmentUploadYes = (email) => {
    try {
      const fileInput = document.createElement("input");
      fileInput.type = "file";

      fileInput.addEventListener("change", (event) => {
        const file = event.target.files[0];

        const reader = new FileReader();

        reader.onloadend = () => {
          const base64data = reader.result.split(",")[1];
          if (!window.concerns[index]) {
            window.concerns[index] = {};
          }
          window.concerns[index].screenshot = base64data;
          window.concerns[index].screenshot_name = ``;
          const message = this.createChatBotMessage(
            "We have got your attachment, please submit if you are done or continue with your Change Request",
            {
              widget: "changerequestsubmit",
            }
          );
          this.setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
            userDetails: { ...prev.userDetails, email },
            waitingFor: "cr",
          }));
        };

        reader.readAsDataURL(file);
      });

      fileInput.click();
    } catch (error) {
      console.error(error);
    }
  };
  handleAttachmentUploadNo = (email) => {
    if (!window.concerns[index]) {
      window.concerns[index] = {};
    }
    window.concerns[index].screenshot = "";
    window.concerns[index].screenshot_name = ``;
    const message = this.createChatBotMessage("We have got your request", {
      widget: "changerequestsubmit",
    });
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "cr",
    }));
  };
  handleName = (name) => {
    window.customername = name;
    const message = this.createChatBotMessage(
      `Please share your Mobile number.`
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, name },
      waitingFor: "phone",
    }));
  };
  handleAnydeskId = async (Id) => {
    window.concerns[index] = {
      message: Id,
      screenshot: "",
      screenshot_name: "",
    };
    const data = {
      id: window.id,
      support_for: window.type,
      name: window.customername,
      email: window.email,
      phone: window.ph,
      concerns: window.concerns,
      url: window.urlofpage,
    };
    const json = JSON.stringify(data);

    const response = await axios.post(
      "http://server.dweb.co.in/dcpl_erp/api/add_ticket.php",
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const message = this.createChatBotMessage(
      `Our representatives will be in touch with you within one working hour (Monday to Saturday, 11:00 AM to 07:00 PM, except National Holidays).`
    );

    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, Id },
    }));
  };
  handleName2 = (name) => {
    const message = this.createChatBotMessage(`Invalid Mobile Number`);
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, name },
      waitingFor: "phone",
    }));
  };

  handlePhone = (phone) => {
    window.ph = phone;
    const message = this.createChatBotMessage("Please share you email.");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, phone },
      waitingFor: "email",
    }));
  };
  handlePhone2 = async (phone) => {
    const message = this.createChatBotMessage("Invalid email address");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, phone },
      waitingFor: "email",
    }));
  };
  handleDomain = async (domain) => {
    const message = this.createChatBotMessage(
      `Hi ${window.customername}, how can we help you?`,
      {
        widget: "options",
      }
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: null,
    }));
  };
  handleEmail = (email) => {
    window.type = "Report a Bug";
    const message = this.createChatBotMessage("Please share your concern.");
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      userDetails: { ...prev.userDetails, email },
      waitingFor: "concern",
    }));
  };

  handleConcern = (concern) => {
    if (window.concerns[index]) {
      index++;
    }
    window.concerns[index] = { message: concern };
    const message = this.createChatBotMessage("Please take the Screenshot", {
      widget: "screenshot",
    });
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "ImageUpload",
      showScreenshotOption: true,
    }));
  };

  handleScreenshot = async (screenshotBlob) => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      const videoTrack = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(videoTrack);

      const screenshotBlob = await imageCapture.grabFrame();

      try {
        const canvas = document.createElement("canvas");
        canvas.width = screenshotBlob.width;
        canvas.height = screenshotBlob.height;
        const context = canvas.getContext("2d");
        context.drawImage(
          screenshotBlob,
          0,
          0,
          screenshotBlob.width,
          screenshotBlob.height
        );

        canvas.toBlob(async (blob) => {
          const reader = new FileReader();
          reader.onloadend = function () {
            let base64data = reader.result;
            base64data = base64data.split("data:image/png;base64,")[1];
            if (!window.concerns[index]) {
              window.concerns[index] = {};
            }
            window.concerns[index].screenshot = base64data;
            window.concerns[index].screenshot_name = `${index}.png`;
          };

          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error("Error creating blob URL:", error);
      }

      stream.getTracks()[0].stop();
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
    window.Sbmt = 0;

    const message = this.createChatBotMessage(
      "Screenshot uploaded. Do you want to submit or continue?",
      {
        widget: "submit",
      }
    );

    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "submitOrContinue",
    }));
  };

  handleNoScreenshot = () => {
    window.Sbmt = 0;
    if (!window.concerns[index]) {
      window.concerns[index] = {};
    }
    window.concerns[index].screenshot = "";
    window.concerns[index].screenshot_name = ``;
    const message = this.createChatBotMessage(
      "We got your concern. Do you want to submit or continue?",
      {
        widget: "submit",
      }
    );

    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "submitOrContinue",
    }));
  };

  handleSecondMessage = (concern) => {
    // index++;
    if (window.concerns[index]) {
      index++;
    }

    window.concerns[index] = { message: concern };
    const message = this.createChatBotMessage(
      "Do you want to submit or continue?",
      {
        widget: "submit",
      }
    );

    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "submitOrContinue",
    }));
  };

  handleSubmit = async () => {
    const data = {
      id: window.id,
      support_for: window.type,
      name: window.customername,
      email: window.email,
      phone: window.ph,
      concerns: window.concerns,
      url: window.urladdress,
    };
    const json = JSON.stringify(data);

    const response = await axios.post(
      "http://server.dweb.co.in/dcpl_erp/api/add_ticket.php",
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.status) {
      const message = this.createChatBotMessage(
        `Your token number is ${response.data.message}, please remember this token number for further use`
      );
      window.Sbmt = 1;

      this.setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
        waitingFor: "last",
        userDetails: {},
      }));
    } else {
      const message = this.createChatBotMessage(
        `Something unexpected happened from our end. We are sorry for that`
      );

      this.setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
        waitingFor: "last",
        userDetails: {},
      }));
    }
  };
  handleAlreadySubmitted = () => {
    const message = this.createChatBotMessage(
      "We have already got your concern and we are working on it.",
      {
        withAvatar: true,
      }
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "last",
      userDetails: {},
    }));
  };

  handleNew = () => {
    const message = this.createChatBotMessage(`Create a new Ticket`, {
      widget: "options",
    });

    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleOtp = async (domain) => {
    window.domain = domain;
    const data = {
      domain_name: domain,
    };
    const json = JSON.stringify(data);

    try {
      const response = await axios.post(
        "http://server.dweb.co.in/dcpl_erp/api/check_domain_exists.php",
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      window.customername = response.data.name;
      window.email = response.data.email;
      window.ph = response.data.phone;
      window.id = response.data.id;
      window.otp = response.data.otp;
      window.msg = response.data.message;
      window.chng = response.data.change_request;
      window.srvc = response.data.service_request;
      window.urladdress = response.data.url;
      window.sts = response.data.status;
    } catch (error) {
      console.error(error);
    }
    let message = "";
    if (window.sts) {
      message = this.createChatBotMessage(window.msg);
    } else {
      message = this.createChatBotMessage(
        "We do not have such Domain/Dedicated IP registered with us, Please Enter the Correct Details"
      );
      window.state = "domain";
    }
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "otp",
    }));
  };

  handleotpverify = async (message) => {
    const data = {
      id: window.id,
      generated_otp: window.otp,
      given_otp: message,
    };
    const json = JSON.stringify(data);
    try {
      const response = await axios.post(
        "http://server.dweb.co.in/dcpl_erp/api/verify_otp.php",
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data.status;
    } catch (error) {
      console.error(error);
    }
  };

  handleOtp2 = async () => {
    const data = {
      domain_name: window.domain,
    };
    const json = JSON.stringify(data);

    try {
      const response = await axios.post(
        "http://server.dweb.co.in/dcpl_erp/api/check_domain_exists.php",
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      window.customername = response.data.name;
      window.email = response.data.email;
      window.ph = response.data.phone;
      window.id = response.data.id;
      window.otp = response.data.otp;
      window.msg = response.data.message;
      window.chng = response.data.change_request;
      window.srvc = response.data.service_request;
      window.urladdress = response.data.url;
      window.sts = response.data.status;
    } catch (error) {
      console.error(error);
    }
    const message = this.createChatBotMessage(
      `OTP Does Not Match. We have send another one. Please your email or whatsapp and enter the new OTP`
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "otp",
    }));
  };

  handleContinue = () => {
    const message = this.createChatBotMessage(
      "Please continue with your concern."
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      waitingFor: "concern",
    }));
  };
}

export default ActionProvider;
