import React from 'react'

const ConcernComponent = (props) => {
  return (<>
    <strong>{props.status}: </strong><>{props.text}</>
  </>
  )
}

ConcernComponent.propTypes = {}

export default ConcernComponent