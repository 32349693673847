import React from 'react'
import "./option.css";

const AdditionalScreenshotOption = ({handleAdditionalScreenshotYes,handleAdditionalScreenshotNo}) => {
    const options = [
        {
          text: "Take a Screenshot",
          handler: handleAdditionalScreenshotYes,
          id: 1,
        },
        {
          text: "Not Applicable",
          handler: handleAdditionalScreenshotNo,
          id: 2,
        },
      ];
    
      const renderedOptions = options?.map((option) => (
        <button className="chat_btn" key={option.id} onClick={option.handler}>
          {option.text}
        </button>
      ));
    
      return <div>{renderedOptions}</div>;
}

export default AdditionalScreenshotOption