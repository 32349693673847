import React from "react";
import "./option.css";

const Options3 = ({ handleSubmit, handleContinue,handleAlreadySubmitted }) => {
  const options = [
    {
      text: "Submit",
      handler: window.Sbmt === 0 ? handleSubmit : handleAlreadySubmitted,
      id: 1,
    },
    {
      text: "Continue",
      handler: window.Sbmt === 0 ? handleContinue : handleAlreadySubmitted,
      id: 2,
    },
  ];

  const renderedOptions = options?.map((option) => (
    <button className="chat_btn" key={option.id} onClick={option.handler}>
      {option.text}
    </button>
  ));
  

  return <div>{renderedOptions}</div>;
};

export default Options3;
