import React from 'react'
import { ColorChangePhoto } from '../Canvas'
import dcpl2 from "./../assets/dcpl2.png"

const CustomAvatar = () => {
  return (
    <div className="justify-content-center" style={{ width: "40px", display:'flex',justifyContent:'flex-start', alignItems:'center', marginRight:'10px' }}>
      <ColorChangePhoto
        imageUrl={dcpl2}
        newColor={{ r: 255, g: 0, b: 0 }}
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      />
    </div>
  )
}

export default CustomAvatar