import React from "react";
import "./option.css";

const Options2 = ({ handleScreenshot, handleNoScreenshot }) => {
  const options = [
    {
      text: "Take a Screenshot",
      handler: handleScreenshot,
      id: 1,
    },
    {
      text: "Not Applicable",
      handler: handleNoScreenshot,
      id: 2,
    },
  ];

  const renderedOptions = options?.map((option) => (
    <button className="chat_btn" key={option.id} onClick={option.handler}>
      {option.text}
    </button>
  ));

  return <div>{renderedOptions}</div>;
};

export default Options2;
